



import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import { US_STATES } from '@/constants';
import Axios from "axios";

class StateLicense {
public _id : string;
public state : string;
public license : string;
public fullname: number;
}

@Component({ components: {  } })
export default class StateLicenses extends Vue {
    public stateLicenses: Array<StateLicense> = [];
    public loader: boolean = false;
    public editing: boolean = false;
    public stateLicenseObject: StateLicense = new StateLicense();
    public defaultSubmitName: string = 'Send';
    public defaultModalAddTitle: string = 'Add State License';
    public defaultModalEditTitle: string = 'Edit State License';
    public states: Array<{ abbrev: string }> = US_STATES;
    public availableStates: Array<{ abbrev: string }> = [];

    public updateExistsLicenses() {
        this.availableStates = this.states
            .filter(state => !this.stateLicenses.find(item => state.abbrev === item.state));
    }

    public async fetchStateLicenses(){
        this.loader = true;
        try {
            const response = await Axios.get(BASE_API_URL+'superAdmin/state-licenses',{});
            this.stateLicenses = response.data;
            this.updateExistsLicenses();
        } catch (error) {
            console.log(error);
        } finally {
          this.loader = false;
        }
    }

    public async addStateLicense(){
        let formValid = await this.$validator.validateAll();
        if (formValid) {
            this.loader = true;
            this.editing = false;
            try {
                const response = await Axios.post(BASE_API_URL+'superAdmin/state-licenses',this.stateLicenseObject);
                this.stateLicenses.push(response.data);
                this.updateExistsLicenses();
                this.loader = false;
                this.$snotify.success("State License added!");
                this.closeModal();
            } catch (error) {
                console.log(error);
                this.loader = false;
            }
        }
    }

    public async editStateLicense(){
        let formValid = await this.$validator.validateAll();
        if (formValid) {
            this.loader = true;
            try {
                const response = await Axios.post(BASE_API_URL + 'superAdmin/edit-state-licenses', this.stateLicenseObject);
                this.stateLicenses = this.stateLicenses.map(item => item._id === response.data._id ? response.data : item);
                this.loader = false;
                this.$snotify.success("State License changed!");
            } catch (error) {
                console.log(error);
                this.loader = false;
                this.editing = false;
            }
        }
    }

    public async removeStateLicense(id: string){
        this.loader = true;
        try {
            await Axios.post(BASE_API_URL+'superAdmin/remove-state-licenses',{
                _id: id
            });
            this.stateLicenses = this.stateLicenses.filter(item => item._id !== id);
            this.updateExistsLicenses();
            this.loader = false;
            this.$snotify.success("State License removed!");
        } catch (error) {
            console.log(error);
            this.loader = false;
        }
    }

    public async openAddStateLicenseModal() {
        this.$modal.show("addStateLicense");
    }

    public async openEditStateLicenseModal(stateLicense: StateLicense) {
        this.stateLicenseObject = stateLicense;
        this.editing = true;
        this.$modal.show("addStateLicense");
    }

    public closeModal() {
        this.editing = false;
        this.stateLicenseObject = new StateLicense();
        this.$modal.hide('addStateLicense');
    }

    mounted() {
        this.fetchStateLicenses();
    }
}
